@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
}

.homepage-title,
.homepage-subtitle {
	width: 100%;
}

.homepage-bios {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.homepage-bio {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.homepage-image {
	width: 125px;
}

.homepage-socials {
	display: flex;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-articles-container {
	display: flex;
}

.homepage-articles {
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 20px;
}
