@import "../../../data/styles.css";

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	top: 3vh;
	z-index: 999;
}

.nav-list {
	display: flex;
	justify-content: center;
	list-style: none;
	align-items: center;
	padding: 0px;
	column-gap: 24px;
	flex-wrap: wrap;
	background: var(--background);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
	padding-left: 24px;
  	padding-right: 24px;
	margin-top: 24px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
	white-space: nowrap;
	padding-top: 10px;
	padding-bottom: 10px;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.nav-item-icon {
	cursor: pointer;
	font-weight: bold;
	font-size: 85%;
	color: var(--primary-color);
}