.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 0px;
	padding-bottom: 20px;
	width: 100%;
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}

@media (max-width: 600px) {
	.articles-main-container {
		padding-top: 80px;
	}
}