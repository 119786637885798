.contact-subtitle {
	width: 100% !important;
}

.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}


@media (max-width: 600px) {
	.contact-container {
		padding-top: 80px;
	}
}