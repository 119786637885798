.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 16px;
	padding-bottom: 24px;
}

.all-projects-project {
	width: 320px;
	box-sizing: border-box;
}
