:root {
	--primary-color: #fefeff;
	--secondary-color: #d8d8db;
	--tertiary-color: #9c9c9c;
	--quaternary-color: #1e1e1f;
	--link-color: #14b8a6;
	--border-color: #111331;
	--background: #0e182c;
	--hover-background: #0e182c;
	--secondary-background: #101e38;

	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
}
